import React, { useRef } from "react";

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ProjectHero from "../../modules/hero/project-hero";
import CoverSection from "../../components/project/cover-section";
import ProjectDetails from "../../components/project/project-details";
import AvntImg from "../../images/avnt/0.jpeg";
import AvntImg1 from "../../images/avnt/1.jpg";
import AvntImg2 from "../../images/avnt/2.png";
import AvntImg3 from "../../images/avnt/3.png";
import AvntImg4  from "../../images/avnt/4.png";
import AvntImg5 from "../../images/avnt/6.jpg";
import FooterMapDetails from "../../components/footer/footer-map-details";

const images = [
  { src: AvntImg1, title: "project avnt1" },
  { src: AvntImg2, title: "project avnt2" },
  { src: AvntImg3, title: "project avnt3" },
  { src: AvntImg4, title: "project avnt4" },
  { src: AvntImg5, title: "project avnt5" },
];

const ProjectTemplate = ({ location }) => {
  const seo = {
    pageUrl: "/projects/avnt",
    pageTitle: "AVNT | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "AVNT | Vello | A full-service creative agency.",
  };

  const pageLayoutRef = useRef();
  const coverSectionUnique = `avnt-section`;

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ProjectHero
          title={"AVNT"}
          client={"AVNT"}
          output={"Mobile App"}
          publishDate={"2020"}
          externalLink={"https://apps.apple.com/us/app/avnt-streetwear-shopping/id1486420898"}
          coverSectionUnique={coverSectionUnique}
          pageLayoutRef={pageLayoutRef}
        />
        <CoverSection
          coverImage={AvntImg}
          coverSectionUnique={coverSectionUnique}
        />

        <ProjectDetails
          overview={
            "AVNT is the fastest growing community of up and coming clothing labels, designers and creatives from around the world. Each week we feature exclusive new releases from the hottest creatives along with their latest interviews, stories, and accomplishments. We are your ultimate source for underground streetwear."
          }
          ourSolution={
            "At Vello, we were drawn to AVNT because of their current dominance in the underground fashion space. There are lots of competitors in fashion but are excelling by sticking true to who they are and what they want to accomplish. As AVNT grows, we are helping them scale and exceed user expectations on stability and usability."
          }
          highlightedText={
            "As AVNT continues to grow, have partnered with them to deliver not only a platform where users can find the most recent fashion releases but also the ability to purchase without leaving the platform."
          }
          images={images}
          nextPageLink={"/projects/sircles"}
          pageLayoutRef={pageLayoutRef}
        />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default ProjectTemplate;
